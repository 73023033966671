/********** Global Css ***************/

* {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', Helvetica;
}

a {
    display: inline-block;
}

a:focus,
.btn:focus {
    outline: 0px;
}

ol,
ul {
    list-style: none;
}

.wrapper {
    /* overflow-x: hidden; */
    height: calc(100% - 45px);
    width: 100%;
    min-width: 1000px;
}


html,
body,
.mainConent,
.main,
.content,
.tabContent {
    /*min-height: 100%;*/
    width: 100%;
    overflow: hidden;
}

body {
    overflow-x: hidden;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 70%);
}
.text-center{
    text-align: center;
}


::-webkit-scrollbar {
    width: 5px;
    left: 0;           
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.header{
    padding: 20px 30px;
    margin: 0px 0px 70px;
}
.search_sec {
    max-width: 764px;
    margin: 0px auto 40px;
    text-align: center;
}
.clock-widget{
    font-size: 160px;
    color: #032588;
    line-height: 160px;
}
.colon{}
.searchForm{
    width: 718px;
    height: 76px;
    background: #fff;
    box-shadow: 0px 3px 4px #00000030;
    border-radius: 50px;
    padding: 8px;
    box-sizing: border-box;
    position: relative;
}
.searchForm .search{
    border: 1px solid #707070;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background: #EDEDED;
    font-size: 20px;
    padding: 0 74px 0 20px;
}
.searchForm .search-btn{
    width: 52px;
    height: 52px;
    background: #009500;
    border: none;
    border-radius: 50%;
    position: absolute;
    right: 13px;
    top: 12px;
    cursor: pointer;
} 

.content{}
.content .blockSec{
    max-width: 450px;
    margin: auto;
    text-align: center;
}
.content .blockSec h3{
    color: #032588;
    font-size: 20px;
    font-weight: 500;
}
.content .blockSec p{
    font-size: 14px;
    color: #fff;
}
.file-select{
    position:relative;
    width: 279px;
    margin: auto;
    padding-top: 1px;
}
.file-select-name{
    width: 265px;
    height: 60px;
    background: #EDEDED;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin: 12px auto;
    border: 1px solid #707070;
    color: #707070;
    box-shadow: 0 0 0 6px #fff;
    filter: drop-shadow(2px 4px 6px #00000054);
}
.file-upload .file-select input[type=file] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.file-selected{
    position: relative;
}
.convertPdf{}
.convertPdf .cancel{
    width: 52px;
    font-size: 12px;
    color: #fff;
    line-height: 8px;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 16px;
}
.convertPdf .cancel .icon-circle{
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.convertPdf .icon-circle img{}
.convertPdf .convert-file{
    width: 52px;
    font-size: 12px;
    color: #fff;
    line-height: 8px;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 16px;
}
.convertPdf .convert-file .icon-circle{
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: #009501;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.convertProcess{
    position:relative;
    text-align: left;
    padding-left: 29px;
    background: #fff;
    padding: 4px 4px 4px 40px;
    color: #032588 !important;
}
.convertProcess:before {
    content: '';
    background: url(../images/loader.png) center center no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 14px;
    background-size: contain;
    top: 50%;
    margin-top: -10px;
    animation: rotating 2s linear infinite;
}

.defaultBtn{
    background: #1854bc;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
}

.defaultBtn img{
    height: 18px;
    vertical-align: sub;
}

.terms_policy_sec{
    font-size: 14px;
    display: flex;
    justify-content: center;
    gap: 1rem;
    .privacy-link{
        border-left: 2px solid grey;
    }
}
.terms_policy_sec ul{
    display: flex;
}
.terms_policy_sec ul a{
    color: #A0A0A0;
    text-decoration: none;
    margin: 0 6px;
}

@keyframes rotating{
    0%{
      transform:rotate(0deg)
    }
    100%{
        transform:rotate(359deg)
      }
}
       

/************* Media Query *****************/
@media (max-width:1200px){
    html, body, .mainConent, .main, .content, .tabContent,.wrapper  {
     overflow-x:auto;
 }
}

@media (max-height:720px){
   .header {
         margin: 0px 0px 70px;
         }
         .search_sec {
         margin: 0px auto 35px;

     }
}
.blockSec{
    display: none;
}